.orProductsCollection {
  display: grid;
  gap: 24px;
  justify-content: center;
  margin-bottom: 24px;
  padding: 24px 12px;
  column-gap: 12px;
  grid-template-columns: 162px 162px;
}

@media screen and (min-width: 768px) {
  .orProductsCollection {
    grid-template-columns: 263px 263px;
    column-gap: 50px;
  }
}

.orImg {
  margin: 0 12px;
  max-width: 100%;
}